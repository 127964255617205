<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ metaTitle && metaTitle.name && metaTitle.name[$i18n.locale] }}
      </h2>
      <a-row class="flex-no__wrap" type="flex">
        <a-button type="primary" @click="modalVisible = true">
          <a-icon type="plus" />{{ $t("Add") }}</a-button
        >
      </a-row>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <b>{{ item.id }}</b>
      </template>
      <template slot="name" slot-scope="item">
        {{ item.name }}
      </template>
      <template slot="operation" slot-scope="item">
        <a-row class="btns" type="flex" align="middle" justify="end">
          <a-button type="primary" class="edit-btn" @click="updateItem(item.id)">
            <a-icon type="edit" class="action-btns" />
          </a-button>
          <!-- <a-popconfirm
            :cancel-text="$t('No')"
            :ok-text="$t('Yes')"
            :title="$t('APopconfirm')"
            @confirm="removeItem(item.id)"
          >
            <a-button type="primary">
              <a-icon class="action-btns" type="delete" />
            </a-button>
          </a-popconfirm> -->
        </a-row>
      </template>
    </a-table>
    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+count || 0"
        @change="toPage"
      />
    </a-row>
    <a-modal :visible="modalVisible" :title="$t('Add')" :closable="false" width="600px">
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px"> {{ $t('Lang') }}: </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px"> {{ $t('TagsColTitle') }}: </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-input v-model="form.name[getKey]" :placeholder="$t('TagsColTitle')" />
          </a-row>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="closeModal">{{ $t("Close") }}</a-button>
        <a-button key="save" type="primary" class="edit-btn" @click="createItem">{{
          $t("Save")
        }}</a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data ()  {
    return {
      form: {
        name: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        category: "",
      },
      langTab: "oz",
      list: [],
      category: {},
      loading: false,
      columns: [
        {
          width: "60px",
          key: "id",
          title: "Id",
          scopedSlots: { customRender: "id" },
          fixed: "left",
        },
        {
          title: "Nomi",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: "20%",
        },
        {
          title: "",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      perPage: 10,
      page: 1,
      offset: 0,
      count: 0,
      modalVisible: false,
      catalogList: [
        {
          name: {
            uz: "Viloyatlar",
            oz: "Вилоятлар",
            ru: "Провинции",
            en: "Провинции",
          },
          category: "region",
        },
        {
          name: {
            uz: "Tumanlar",
            oz: "Туманлар",
            ru: "Районы",
            en: "Районы",
          },
          category: "district",
        },
      ],
    };
  },
  computed: {
    getKey() {
      return this.langTab;
    },
    metaTitle() {
      return this.catalogList.find((el) => el.category == this.$route.params.category);
    },
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.offset = +this.$route.query.page || 1;
      this.page = this.offset;
      this.offset = (this.offset - 1) * this.perPage;
      let { data } = await this.$api.get("/admin/catalog/list", {
        params: {
          category: this.$route.params.category,
          limit: this.perPage,
          offset: this.offset,
        },
      });
      if (data.status) {
        this.list = (data.data && data.data.results) || [];
        this.count = data.data && data.data.count;
      }
      this.loading = false;
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } });
    },
    async createItem() {
      try {
        this.form.category = this.$route.params.category;
        if (this.form.id) {
          let { data } = await this.$api.put(`/admin/catalog/${this.form.id}`, this.form);
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        } else {
          let { data } = await this.$api.post("/admin/catalog/create", this.form);
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeModal() {
      this.modalVisible = false;
      this.form = {
        name: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        category: "",
      };
    },
    async removeItem(id) {
      try {
        const { data } = await this.$api.delete(`/admin/catalog/${id}`);
        if (data.status) {
          this.$message.success("Успешно удалена");
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateItem(id) {
      try {
        const { data } = await this.$api.get(`/admin/catalog/${id}`);
        if (data.status) {
          this.form = data.data;
          this.modalVisible = true;
        } else {
          this.$message.error(data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
